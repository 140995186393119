<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="box clearfix">
        <user-left :name="'userinfo'"></user-left>
        <div class="user-right" ref="right">
          <div class="title-top"><strong>修改资料</strong></div>
            <div class="edit-photo">
              <img id="photoImg"
                :src="headimg"
                onerror="this.src = '../assets/img/img_default_photo.png'" />
            </div>
            <div class="btn-box a-ta-c">
              <button class="btn a-pos-r">
                <span>选择图片</span>
                <input class="upload-input" type="file" accept="image/png,image/gif,image/jpeg"
                  @change="getPickerImg($event)" />
              </button>
            </div>
            <div class="form-box">
              <div class="a-of-h">
                <div class="item-name">姓名</div>
                <div class="item-input">
                  <input v-model="userinfo.nickname" placeholder="请输入姓名" />
                </div>
              </div>
              <div class="a-of-h">
                <div class="item-name">出生日期</div>
                <div class="item-input">
                  <input v-model="userinfo.age" placeholder="请输入出生日期" />
                </div>
              </div>
              <div class="a-of-h">
                <div class="item-name">性别</div>
                <div class="item-input">
                  <input v-model="userinfo.company" placeholder="请输入性别" />
                </div>
              </div>
              <div class="a-of-h">
                <div class="item-name">联系电话</div>
                <div class="item-input">
                  <input v-model="userinfo.position" placeholder="请输入联系电话" />
                </div>
              </div>
              <button type="button" class="btn" @click="submitForm">提交</button>
            </div>
        </div>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
    <qiniu-upload ref="qiniuUpload"></qiniu-upload>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import FooterNav from '@/components/FooterNav'
import QiniuUpload from '@/components/QiniuUpload'
import UserLeft from '@/components/UserLeft'

export default {
  name: 'user',
  data () {
    return {
      current: 'user',
      userinfo: {
        nickname: '',
        age: '',
        avatar: '',
        company: '',
        position: ''
      },
      headimg: ''
    }
  },
  methods: {
    getMember: function () {
      this.$post('user/getMember').then(res => {
        if (res.code === 1) {
          this.userinfo = res.data
          this.headimg = res.data.avatar
        }
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
      })
    },
    submitForm: function () {
      // 提交修改
      let img = document.getElementById('photoImg')
      let imgFiles = document.getElementById('photoFile')
      if (!this.userinfo.nickname) {
        this.$layer.msg('请输入昵称！')
        return true
      }
      if (!this.userinfo.age) {
        this.$layer.msg('请输入年龄！')
        return true
      }
      if (!this.userinfo.company) {
        this.$layer.msg('请输入单位名称！')
        return true
      }
      if (!this.userinfo.position) {
        this.$layer.msg('请输入个人职称！')
        return true
      }
      let params = {
        nickname: this.userinfo.nickname,
        age: this.userinfo.age,
        company: this.userinfo.company,
        position: this.userinfo.position,
        avatar: this.headimg
      }
      this.$post('user/changeInfo', params).then(res => {
        this.$layer.msg(res.msg)
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
      })
    },
    getPickerImg: function (e) {
      let files = e.target.files
      let qiniu = this.$refs.qiniuUpload
      qiniu.qiniuUpload(files, (res) => {
        console.log(1111111, res)
        this.headimg =  this.GLOBAL.qnDomail + res[0].key
      })
    }
  },
  components: {
    HeaderNav,
    FooterNav,
    UserLeft,
    QiniuUpload
  },
  mounted: function () {
    var h = document.documentElement.clientHeight || document.body.clientHeight
    this.$refs.content.style.minHeight = h - 124 + 'px'
    this.$refs.right.style.minHeight = h - 144 + 'px'
  },
  created () {
    // 获取用户信息
    this.getMember()
  }
}
</script>

<style lang="less" scoped>
@import url('../assets/css/page/edit');
</style>
